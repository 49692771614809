<template>
	<div
		v-if="!program.not_side"
		class="side bg-333"
	>
		<div
			class="side-container flex-column"
		>
			<ul
				class="side-content overflow-y pb-50"
			>
				<template
					v-for="item in items"
				>
					<li
						v-if="!item.not_view && item.type.indexOf(user.role) > -1"
						:key="item.path"
						class="li-side-content cursor-pointer"
						:class="'li-side-depth-' + item.depth"
					>
						<div
							class="pa-10 justify-space-between"
						>
							<span
								class="font-weight-bold flex-column justify-center ptb-5"
							>{{ item.name }}</span>
						</div>
						<ul>
							<template
								v-for="(sub, index) in item.sub"
							>
								<li
									v-if="!sub.not_view && sub.type.indexOf(user.role) > -1 && (!sub.in || $common.getPowNumber(user.sales_type, sub.in))"
									:key="item.path + sub.path + '_' + index"
									class="pa-10 "
									:class="is_selected(item, sub)"
									@click="toPush(item, sub)"
								>
									<div
										class="justify-space-between"
									>
										<span class="flex-column justify-center ptb-5">{{ sub.name }}</span>
										<v-icon
											v-show="!is_selected(item, sub)"
											class="color-icon-dark mdi mdi-chevron-right"
											name="chevron-right"
										></v-icon>
									</div>
								</li>
							</template>
						</ul>
					</li>
				</template>
			</ul>
		</div>
	</div>
</template>

<script>
export default{
	name: 'Side'
	,props: ['Axios', 'user', 'program']
	,data: function(){
		return {
			items: [
				{
					path: 'Agency'
					,name: '영업점 관리'
					,type: ['manager', 'admin', 'distributor']
					, sub: [
						{
							path: 'List'
							,name: '영업점 목록'
							,type: ['manager', 'admin', 'distributor']
							, params: { type: 'A001003'}
						}
						,{
							path: 'Wallet'
							,name: '영업점 지갑 목록'
							,type: ['manager', 'admin', 'distributor']
						}
						,{
							path: 'WalletHistory'
							,name: '영업점 입출금 내역'
							,type: ['manager', 'admin', 'distributor']
						}
					]
				}
				, {
					path: 'Agency'
					,name: '입출금 관리'
					,type: ['agency']
					, sub: [
						{
							path: 'WalletHistory'
							,name: '입출금 내역'
							,type: ['agency']
						}
					]
				}
				, {
					path: 'Member'
					,name: '회원 관리'
					,type: ['manager', 'admin', 'distributor', 'agency']
					, sub: [
						{
							path: 'List'
							,name: '회원 목록'
							,type: ['manager', 'admin', 'distributor', 'agency']
						}
						, {
							path: 'Wallet'
							,name: '회원 지갑 목록'
							,type: ['manager', 'admin', 'distributor', 'agency']
						}
						, {
							path: 'WalletHistory'
							,name: '회원 입출금 내역'
							,type: ['manager', 'admin', 'distributor', 'agency']
						}
					]
					, not_view: true
				}
				,{
					path: 'Settlement'
					, name: '가상계좌 정산 관리'
					, type: ["admin", "distributor", "agency"]
					, sub: [

						{
							path: 'DepositHistory'
							,name: '승인 입금 내역'
							,type: ['manager', 'admin', 'distributor', 'agency']
						}
						,{
							path: 'Deposit'
							,name: '입금 정산 내역'
							,type: ['manager', 'admin', 'distributor', 'agency']
						}
						,{
							path: 'List'
							,name: '입금 정산 실행'
							,type: ["admin", "distributor", "agency"]
						}
						,{
							path: 'Minus'
							,name: '정산 차감'
							,type: []
						}
						, {
							path: 'Search'
							,name: '입금 정산 조회'
							,type: ['admin']
							, not_view: true
						}
					]
					, not_view: true
				}
				, {
					path: 'Wallet'
					,name: '지갑 관리'
					,type: ['manager', 'admin', 'distributor', 'agency']
					, sub: [
						{
							path: 'Deposit'
							,name: '예치금 입금 내역'
							,type: ['manager', 'admin', 'distributor']
						}

						, {
							path: 'Deposit'
							,name: '예치금 내역'
							,type: ['agency']
						}
						,{
							path: 'Member'
							,name: '회원 지급 내역'
							,type: ['manager', 'admin', 'distributor', 'agency']
							, in: 1
						}
						,{
							path: 'MemberAct'
							,name: '회원 지급 실행'
							,type: ['agency']
							, in: 1
						}
						, {
							path: 'List'
							,name: '지갑 목록'
							,type: ['manager']
						}

					]
				}
				,{
					path: 'Statistics'
					, name: '통계 관리'
					, type: ['manager', 'admin', 'distributor']
					, sub: [
						{
							path: 'List'
							, name: '월별 통계'
							, type: ['admin', 'distributor']
						}
					]
				}
				,{
					path: 'Virtual'
					, name: '연동관리 목록'
					, type: ['manager', 'admin']
					, sub: [
						{
							path: 'List'
							, name: '가상계좌 관리'
							, type: ['admin']
						}
					]
				}
				,{
					path: 'Pg'
					, name: 'PG 관리'
					, type: []
					, sub: [
						{
							path: 'List'
							, name: 'PG 목록'
							, type: ['admin']
						}
					]
				}
				,{
					path: 'Mypage'
					, name: '정보 관리'
					, type: ['distributor', 'admin', 'agency']
					, sub: [
						{
							path: 'Agency'
							, name: '마이페이지'
							, type: ['distributor', 'admin', 'agency' ]
						}
					]
				}
			]
			,item_new: {

			}
		}
	}
	,computed: {
	}
	,methods: {
		toPush: function(menu, sub){
			console.log('toPush : ' +menu.path + sub.path)
			this.$storage.setQuery({ page: 1, list_cnt: 10})
			this.$bus.$emit('to', { name: menu.path + sub.path, params: sub.params})
		}
		, is_selected: function(item, sub){
			let t = ''

			if(this.$route.name == item.path + sub.path){
				t = 'bg-black'
			}

			return t
		}
	}
	,created: function(){
		console.log('in side !!' + this.$route.name, this.user)
	}
}
</script>

<style>

.side {
	width: 200px;
	height: 100%;
	color: #bbb
}
.bg-side {
	width: 100%; height: 100%;
	position: fixed; left: 0; top: 0; z-index: 98;
	opacity: 0.5;
}
.side-container{
	height: 100%;
	position: relative;  z-index: 99;
	overflow-y: auto;
	border-right: 1px solid #ddd
}

.side-container {

}

.li-side-content {
	border-bottom: 1px solid #ddd;
}
.li-side-depth-1 {
	text-indent: 0px;
}
.li-side-depth-2 {
	text-indent: 10px;
}
.li-side-depth-3 {
	text-indent: 20px;
}
.li-side-depth-4 {
	text-indent: 30px;
}
</style>